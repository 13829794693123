import React from 'react'
import "./css/Gallery.css"

const Gallery = () => {
  return (
    <>
    <div className="container-fluid">
    <div className="first">
        
        <div className="one">
            <img src="./images1/g1.jpg" alt="" />
        </div>
        <div className="one">
            <img src="./images1/g2.jpg" alt="" />
        </div>
        <div className="one">
            <img src="./images1/g3.jpg" alt="" />
        </div><div className="one">
            <img src="./images1/g4.jpg" alt="" />
        </div>
    </div>
        <div className="first">
        
            <div className="one">
                <img src="./images1/g5.jpg" alt="" />
            </div>
            <div className="one">
                <img src="./images1/g1.jpg" alt="" />
            </div><div className="one">
                <img src="./images1/g7.jpg" alt="" />
            </div><div className="one">
                <img src="./images1/g8.jpg" alt="" />
            </div>
        </div>
        <div className="first">
            <div className="one">
                <img src="./images1/g9.jpg" alt="" />
            </div>
            <div className="one">
                <img src="./images1/g4.jpg" alt="" />
            </div><div className="one">
            <img src="./images1/g2.jpg" alt="" />
            </div><div className="one">
            <img src="./images1/g1.jpg" alt="" />
            </div>
        </div>
    </div>
    </>
  )
}

export default Gallery